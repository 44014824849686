import { Currency } from "#client/common/ui/Currency.tsx";
import { LinkButton } from "#client/common/ui/LinkButton.tsx";
import { cn } from "#client/common/utils.ts";
import { CheckIcon } from "@heroicons/react/20/solid";

export function PricingSection(props: {
  tiers: {
    plus: {
      id: string;
      name: string;
      priceUSD: number;
      description: string;
      features: Array<string>;
      bestValue: boolean;
    };
    premium: {
      id: string;
      name: string;
      priceUSD: number;
      description: string;
      features: Array<string>;
      bestValue: boolean;
    };
  };
}) {
  const {
    tiers: { plus, premium },
  } = props;
  const tiers = [plus, premium];

  return (
    <div id="pricing" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-brand-mint_green-900">
            Pricing
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Premium quality, at an affordable price
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Collect photos and videos of your special day with a low one-time
          payment. Upload and storage period{" "}
          <span className="font-bold">
            starts on a date of your choosing, not the purchase date.
          </span>
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center mt-16 sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={cn(
                  "flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 sm:p-10",
                  tier.bestValue
                    ? "ring-brand-mint_green-700"
                    : "ring-gray-900/10",
                )}
              >
                <div>
                  <div className="flex items-center justify-between gap-x-4">
                    <h3 className="text-base font-semibold leading-7 text-brand-mint_green-900">
                      {tier.name}
                    </h3>
                    {tier.bestValue ? (
                      <p className="rounded-full bg-brand-mint_green-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-brand-mint_green-900">
                        Best value
                      </p>
                    ) : null}
                  </div>
                  <div className="mt-4 flex items-baseline gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      <Currency amount={tier.priceUSD} currency="USD" />
                    </span>
                    <span className="text-base font-semibold leading-7 text-gray-600">
                      /one-time
                    </span>
                  </div>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    {tier.description}
                  </p>
                  <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-brand-mint_green-800"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                <LinkButton
                  to="/signup"
                  aria-describedby={tier.id}
                  intent={tier.bestValue ? "primary_green" : "outline_green"}
                  size="full"
                  className="mt-8"
                >
                  Get started
                </LinkButton>
              </div>
            ))}
            {/* <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-brand-mint_green-900">
                  Gift Card
                </h3>
                <p className="mt-1 text-base leading-7 text-gray-600">
                  Purchase for someone special in your life. A gift code will be
                  emailed to you after your purchase.
                </p>
              </div>
              <Link
                to="/admin/purchase-gift-card"
                className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-brand-mint_green-900 ring-1 ring-inset ring-brand-mint_green-900 hover:ring-brand-mint_green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-mint_green-900"
              >
                Buy Gift Card <span aria-hidden="true">&rarr;</span>
              </Link>
            </div> */}
          </div>
        </div>

        {/* <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl bg-white p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
          <div className="lg:min-w-0 lg:flex-1">
            <h3 className="text-lg font-semibold leading-8 tracking-tight text-brand-mint_green-900">
              Gift Card
            </h3>
            <p className="mt-1 text-base leading-7 text-gray-600">
              Purchase for someone special in your life. A gift code will be
              emailed to you after your purchase.
            </p>
          </div>
          <LinkButton to="/signup" intent="outline_green">
            Get started <span aria-hidden="true">&rarr;</span>
          </LinkButton>
        </div> */}
      </div>
    </div>
  );
}
